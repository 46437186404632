import React, { useState, useRef, useEffect } from 'react'

import { breakpoints } from '@smarty-nx/ds'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import LoadingDots from '../components/LoadingDots'
import PageHead from '../components/PageHead'
import SiteContainer from '../components/styles/SiteContainer'
import { Paragraph } from '../components/styles/Text'
import withApp from '../components/withApp/withApp'
import { CONFIG } from '../config'
import dsTheme from '../constants/design-system/theme.json'

const { typography } = dsTheme

const LoadingState = styled.div`
  position: absolute;
  top: 16%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (min-width: ${breakpoints.tablet}px) {
    top: 10%;
  }
`

const Title = styled.h1`
  margin: 64px 0 16px;
  padding-left: 24px;
  padding-right: 24px;
  ${typography['Kollektif/Mega']};
  background-color: #333539;

  @media screen and (max-width: 640px) {
    margin-top: 24px;
    margin-bottom: 8px;
    text-align: center;
    ${typography['Kollektif/Large']};
  }
`

const Subtext = styled.p`
  max-width: 500px;
  width: 48%;
  padding-left: 24px;
  ${typography['Kollektif/Medium']};

  @media screen and (max-width: 640px) {
    width: 100%;
    max-width: 100%;
    padding-right: 24px;
    text-align: center;
    ${typography['Kollektif/ExtraSmall']};
  }
`

const Container = styled.div`
  position: relative;
  z-index: 10;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
`

const NetworkStatusChecker = () => {
  const iframeRef = useRef()
  const currentIframe = iframeRef.current
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (currentIframe) {
      setLoaded(true)
    }
  }, [currentIframe])

  return (
    <SiteContainer
      css={css`
        width: 100%;
        max-width: 100vw;
        position: relative;
        padding: 0;
        background-color: #333539;
      `}
    >
      {!loaded && (
        <LoadingState>
          <LoadingDots
            css={css`
              width: 80px;
            `}
          />
          <Paragraph>Loading...</Paragraph>
        </LoadingState>
      )}
      <div
        loading="lazy"
        css={css`
          opacity: 0;
          visibility: hidden;
          transition: all 350ms cubic-bezier(0.65, 0.05, 0.36, 1);
          will-change: opacity, visibility;
          ${loaded && `opacity: 1; visibility: visible;`}
        `}
      >
        <Container>
          <Title>Network Status Checker</Title>
          <Subtext>
            Get more information about the current network situation in your
            area
          </Subtext>
        </Container>
        <iframe
          ref={iframeRef}
          title="network-status-checker"
          src={CONFIG.NETWORK_STATUS_CHECKER_LINK}
          height={600}
          onLoad={() => setLoaded(true)}
          data-testid="network-status-checker-iframe"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          style={{
            width: '100%',
            border: 'none',
            margin: '0px auto',
            marginTop: '-50px',
            background: 'white',
            display: 'block',
            overflow: 'hidden',
            paddingTop: 0,
          }}
        />
      </div>
    </SiteContainer>
  )
}

NetworkStatusChecker.propTypes = {
  left: PropTypes.bool,
}

export default withApp(NetworkStatusChecker)

export const Head = () => (
  <PageHead
    title="Network status checker"
    description="Check your SMARTY network status for up to date information"
  >
    <meta name="referrer" content="same-origin" />
  </PageHead>
)

/*
TODO: Refactor this logic by removing legacy Main Nav component first and prepare it to be contentful driven (https://smarty-mobile.atlassian.net/browse/DIGDEV-5841).
 */
import React, { useCallback, useEffect, useState } from 'react'

import {
  useEventOutside,
  useInMediaRange,
  useOnKey,
} from '@smarty-nx/code-utils'
import { Button, Icon } from '@smarty-nx/ds'
import cn from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import classes from './navbar.module.scss'
import { CONFIG } from '../../config'
import { TRACKING_CATEGORIES } from '../../constants/globalStrings'
import { useIsAuthenticated } from '../../hooks'
import Hamburger from '../Hamburger'

const CoverageLink = ({ handleClick, placement, ...props }) => {
  return (
    <Link
      className={cn(classes.link, classes.coverageLink)}
      onClick={handleClick}
      to="/coverage-checker"
      data-item-category={TRACKING_CATEGORIES.NAVIGATION}
      data-item-name="coverage-checker"
      data-testid="menu-coverage-checker-link"
      data-placement={placement} // for custom CSS styles
      {...props}
    >
      Check coverage
    </Link>
  )
}

CoverageLink.propTypes = {
  handleClick: PropTypes.func,
  placement: PropTypes.string,
}

const RedesignLinks = ({ handleClick }) => {
  const isAuthenticated = useIsAuthenticated()
  const [isGroupOpen, setGroupOpen] = useState(false)
  const toggleGroup = () => setGroupOpen((value) => !value)
  const closeSubLinks = () => setGroupOpen(false)
  const ref = useEventOutside(closeSubLinks)
  useOnKey('Escape', closeSubLinks)

  // no space for "Coverage checker" - that's why its show in other place at some breakpoints
  const inSpecialRange = useInMediaRange(
    parseInt(classes.specialWidthStart, 10),
    parseInt(classes.specialWidthEnd, 10)
  )

  return (
    <div className={classes.navLink}>
      <Link
        className={classes.link}
        onClick={handleClick}
        to="/all-plans"
        data-item-category={TRACKING_CATEGORIES.NAVIGATION}
        data-item-name="all-plans"
        data-testid="menu-all-plans-link"
      >
        All plans
      </Link>

      {!isAuthenticated && (
        <CoverageLink handleClick={handleClick} placement="menu" />
      )}

      <Link
        className={classes.link}
        onClick={handleClick}
        to="/refer-a-friend"
        data-item-category={TRACKING_CATEGORIES.NAVIGATION}
        data-item-name="refer-a-friend"
        data-testid="menu-raf-link"
      >
        Earn up to £20
      </Link>
      <Link
        className={classes.link}
        onClick={handleClick}
        to="/about-us"
        data-item-category={TRACKING_CATEGORIES.NAVIGATION}
        data-item-name="about-us"
        data-testid="menu-about-link"
      >
        About
      </Link>
      <Link
        className={classes.link}
        onClick={handleClick}
        to="/group-plans"
        data-item-category={TRACKING_CATEGORIES.NAVIGATION}
        data-item-name="group-plans"
        data-testid="menu-group-plans-link"
      >
        Group plans
      </Link>

      <div
        ref={ref}
        className={cn(
          classes.link,
          classes.linkGroup,
          isGroupOpen ? classes.groupOpen : classes.groupClosed
        )}
        onClick={toggleGroup}
        data-item-category={TRACKING_CATEGORIES.NAVIGATION}
        data-item-name="help"
        data-testid="menu-help-link"
      >
        Help
        <Icon name="chevron" />
        {isGroupOpen && (
          <div className={classes.subLinks} data-testid="menu-sublinks">
            <a
              className={classes.link}
              onClick={handleClick}
              href="https://help.smarty.co.uk/en/"
              rel="noopener noreferrer nofollow"
              target="_blank"
              data-item-category={TRACKING_CATEGORIES.NAVIGATION}
              data-item-name="self-service"
              data-testid="menu-self-service-link"
            >
              Self-service
            </a>

            <a
              className={classes.link}
              onClick={handleClick}
              href={CONFIG.COMMUNITY_SITE_URL}
              target="_blank"
              rel="noopener noreferrer nofollow"
              data-item-category={TRACKING_CATEGORIES.NAVIGATION}
              data-item-name="ask-the-community"
              data-testid="menu-ask-the-community-link"
            >
              Ask the Community
            </a>

            <Link
              className={classes.link}
              onClick={handleClick}
              to="/contact"
              data-item-category={TRACKING_CATEGORIES.NAVIGATION}
              data-item-name="contact-us"
              data-testid="menu-contact-us-link"
            >
              Contact us
            </Link>

            <Link
              className={classes.link}
              onClick={handleClick}
              to="/network-status-checker"
              data-item-category={TRACKING_CATEGORIES.NAVIGATION}
              data-item-name="network-status-checker"
              data-testid="menu-network-status-checker-link"
            >
              Network status checker
            </Link>

            {(isAuthenticated || inSpecialRange) && (
              <CoverageLink handleClick={handleClick} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

RedesignLinks.propTypes = {
  handleClick: PropTypes.func,
}

const RedesignActionLinks = ({ navbarOpen }) => {
  const isAuthenticated = useIsAuthenticated()
  const isMobile = useInMediaRange()

  return (
    <div
      className={cn(
        classes.rightLinks,
        !navbarOpen && classes.rightLinksVisible
      )}
    >
      {!isAuthenticated && (
        <>
          <Button
            size="tiny"
            level="secondary"
            data-testid="menu-check-coverage-button"
            data-item-name="check-coverage"
            data-item-category={TRACKING_CATEGORIES.USER_ACCOUNT}
            className={classes.coverageLink}
            placement="header"
            as={(props) => <CoverageLink {...props} />}
          />
          <Button
            size="tiny"
            data-testid="menu-login-button"
            data-item-name="log-in"
            data-item-category={TRACKING_CATEGORIES.NAVIGATION}
            as={(props) => {
              return (
                <a
                  href={`${
                    CONFIG.SMARTY_ENV === 'localhost'
                      ? CONFIG.DASHBOARD_URL
                      : '/'
                  }login`}
                  {...props}
                >
                  Log in
                </a>
              )
            }}
          />
        </>
      )}

      {isAuthenticated && (
        <Button
          size="tiny"
          data-testid="menu-dashboard-button"
          data-item-category={TRACKING_CATEGORIES.NAVIGATION}
          data-item-name="go-to-dashboard"
          as={(props) => (
            <a {...props} href={CONFIG.DASHBOARD_URL}>
              {isMobile ? 'Dashboard' : 'Go to my dashboard'}
            </a>
          )}
        />
      )}
    </div>
  )
}

RedesignActionLinks.propTypes = {
  navbarOpen: PropTypes.bool,
}

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const KEYCODE_TAB = 9
  const handleResize = useCallback(() => {
    if (window.innerWidth > 850) setNavbarOpen(false)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown)
    return () => window.removeEventListener('keydown', handleKeydown)
  })

  const toggleNav = () => {
    setNavbarOpen((existing) => !existing)
  }

  const closeNav = () => {
    setNavbarOpen(false)
  }

  const handleKeydown = useCallback(
    (e) => {
      const navEl = document.getElementById('nav')
      const focusable = navEl.querySelectorAll(
        'button, [href], input, select, textarea, li, a,[tabindex]:not([tabindex="-1"])'
      )
      const firstFocusable = focusable[0]
      const lastFocusable = focusable[focusable.length - 1]
      const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB

      if (!isTabPressed || !navbarOpen) {
        return
      }

      if (e.shiftKey) {
        /* shift + tab */
        if (document.activeElement === firstFocusable) {
          lastFocusable.focus()
          e.preventDefault()
        }
      } /* tab */ else {
        /* eslint-disable no-lonely-if */
        if (document.activeElement === lastFocusable) {
          firstFocusable.focus()
          e.preventDefault()
        }
        /* eslint-enable no-lonely-if */
      }
    },
    [navbarOpen]
  )

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (navbarOpen) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = 'unset'
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [navbarOpen, handleKeydown])

  return (
    <nav
      id="nav"
      role="navigation"
      data-testid="navbar-container"
      className={cn(
        classes.navbarContainer,
        navbarOpen && classes.open,
        classes.redesignNav
      )}
    >
      <div className={cn(classes.navbarInner, navbarOpen && classes.open)}>
        <Link
          className={classes.logo}
          to="/"
          data-item-category={TRACKING_CATEGORIES.NAVIGATION}
          data-item-name="home"
          data-testid="navbar-logo-link"
          onClick={navbarOpen ? toggleNav : null}
        >
          Home
        </Link>

        <RedesignActionLinks navbarOpen={navbarOpen} />

        <Hamburger open={navbarOpen} onClick={toggleNav} />

        <div
          className={cn(classes.subNav, navbarOpen && classes.open)}
          data-testid="subnav"
        >
          <div className={cn(classes.linksWrapper, navbarOpen && classes.open)}>
            <RedesignLinks handleClick={closeNav} />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar

import React from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { initDatadogRum } from '@smarty-nx/debug-utils'
import { Provider } from 'react-redux'
import { ParallaxProvider } from 'react-scroll-parallax'
import { ThemeProvider } from 'styled-components'

import 'url-search-params-polyfill'

import CustomLayout from './gatsby-shared'
import { Layout } from './src/components/Layout/Layout'
import { FEATURES } from './src/config'
import { theme } from './src/constants/theme'
import { store } from './src/state/createStore'
import { saveState } from './src/state/localStorage'
import { attach, setDmpgObject } from './src/static/scripts/dmpg'
import './src/static/scripts/genesys'
import { getAffiliate, setQRRedirect } from './src/utils/affiliates'
import { createCookie } from './src/utils/cookie'
import { boot, update } from './src/utils/intercom'

const HIDE_INTERCOM_PATHS = new RegExp(/^\/friend(\/)*$/)

// Save auth info to localeStorage on each change
store.subscribe(() => saveState(store.getState()))

const scrollTo = (id) => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}

const activate = () => {
  if (window.dataLayer) {
    window.dataLayer.push({ event: 'optimize.activate' })
  }
}

export const onRouteUpdate = ({ location }) => {
  activate()

  datadogRum.startView(location.href)

  const { hash } = location
  if (hash) {
    window.setTimeout(scrollTo(hash), 10)
  }
}

export const onPreRouteUpdate = ({ location }) => {
  // Toggling Intercom depending on route
  if (HIDE_INTERCOM_PATHS.test(location.pathname)) {
    return update({ hide_default_launcher: true })
  }

  return update({ hide_default_launcher: false })
}

export const onClientEntry = async () => {
  const urlSearch = window.location.search
  const urlPath = window.location.pathname
  const { referrer } = document
  const affiliate = getAffiliate({ urlSearch, referrer })

  if (affiliate) {
    createCookie({ name: 'affiliate', value: affiliate, days: 30 })
  }

  if (urlPath === '/data-sim' || urlPath === '/data-sim/') {
    setQRRedirect(urlSearch)
  }

  // Start Intercom
  boot()

  setDmpgObject()
  attach(document, window.smDl)

  initDatadogRum({
    applicationId: process.env.GATSBY_DATADOG_APP_ID,
    clientToken: process.env.GATSBY_DATADOG_TOKEN,
    site: process.env.GATSBY_DATADOG_HOST,
    service: process.env.GATSBY_DATADOG_SERVICE_NAME,
    env: process.env.GATSBY_SMARTY_ENV,
    commit: process.env.GATSBY_COMMIT,
    sessionReplay: process.env.GATSBY_SMARTY_ENV !== 'localhost',
    sessionReplaySampleRate: Number(
      process.env.GATSBY_DATADOG_SESSION_REPLAY_SAMPLE_RATE
    ),
    defaultPrivacyLevel: process.env.GATSBY_DATADOG_DEFAULT_PRIVACY_LEVEL,
  })
}

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ParallaxProvider>{element}</ParallaxProvider>
    </ThemeProvider>
  </Provider>
)

// props provide same data to Layout as Page element will get
// including location, data, etc - you don't need to pass it
export const wrapPageElement = FEATURES.USE_GATSBY_SCRIPT
  ? CustomLayout
  : ({ element, props }) => <Layout {...props}>{element}</Layout>

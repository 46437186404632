const contentfulTypes = [
  'addOn',
  'addOnCarousel',
  'addOnType',
  'dismissibleCard',
  'faq',
  'footer',
  'genericBanner',
  'homepageBanner',
  'pageSection',
  'plan',
  'planMapping',
  'planUpsell',
  'exitIntentOverlays',
  'plans',
  'roamingPageBanner',
  'thisPlanIncludesGroup',
  'page',
  'redirects',
]

const genericBannerNames = {
  ASK_COMMUNITY: 'ask-community',
  HELP_CENTRE: 'smarty-help-centre',
  TRY_OUR_APP: 'try-our-app',
  CHRISTMAS_OPENING_HOURS: 'christmas-opening-hours',
  PLAN_BENEFITS: 'plan-benefits-dashboard-fp-invitation',
  FP_QUESTIONS: 'questions-dashboard-fp-invitation',
  FP_WHAT_YOU_CAN_DO: 'what-you-can-do-description-dashboard-fp-invitation',
  FP_THE_MEMBERS: 'the-members-dashboard-fp-invitation',
  FP_THE_OWNER: 'the-owner-dashboard-fp-invitation',
  FP_WELCOME_TO_GROUP: 'welcome-to-the-group-dashboard-fp',
  FP_WELCOME_TO_SMARTY: 'welcome-to-smarty-fp',
  FP_INVITE_REJECTED: 'invite-rejected-fp-dashboard',
  FP_SOLO_PLANS: 'we-also-have-solo-plans-fp-dashboard',
  INVITATION_SENT: 'invitation-sent-fp',
  INVITATION_RESEND: 'invitation-resend-fp',
  RAF_BANNER: 'refer-a-friend-personalised-banner',
  RAE_BANNER_BY_REFERRAL_LINK: 'refer-and-earn-banner-by-referral-link',
  RAE_BANNER_BY_BONUS_SIM: 'refer-and-earn-banner-by-bonus-sim',
  RAE_BANNER_SEND_BY_POST: 'refer-and-earn-send-sim-by-post',
  RAE_BANNER_A_FREE_SIM_BY_POST: 'refer-and-earn-order-a-free-sim-by-post',
  INVALID_INVITATION_IMAGE: 'invalid-invitation-image',
  SWITCH_TO_SMARTY: 'switch-to-smarty',
  EVERYONE_UNLIMITED: 'everyone-unlimited',
  ACTIVATE_YOUR_SIM_FIRST: 'you-need-to-activate-your-sim-first',
  FP_REQUEST_ACTIVATION: 'request-activation-when-you-receive-your-sim-fp',
  FP_ACTIVATION_FAILED: 'activation-failed-fp',
  FP_ACTIVATE_NOW_OWNER: 'activate-now-owner-fp',
  FP_ACTIVATE_NOW_OWNER_AS_MEMBER: 'activate-now-owner-as-member-fp',
  FP_ACTIVATE_NOW_MEMBER: 'activate-now-member-fp',
  SWITCHING_YOUR_SERVICES_WARNING: 'switching-your-services-warning',
  FP_WHAT_IS_STAC: 'what-is-a-stac-code-fp',
  FP_WHAT_IS_PAC: 'what-is-a-pac-code-fp',
  GOT_YOUR_DETAILS_WHAT_NEXT: 'whats-next',
  FP_INVALID_ACCOUNT: 'invalid-account-fp',
  FP_MEMBER_INVITATION_SENT: 'member-invitation-sent-fp',
  FP_MEMBER_INVITATION_ACCEPTED: 'invitation-accepted-fp',
  FP_REJECT_INVITE: 'reject-invitation-fp',
  FP_ACTIVATION_PENDING: 'activation-pending-fp',
  FP_OWNER_ACTIVATE_SIM: 'activate-own-sim-fp',
  FP_MEMBER_ACTIVATE_SIM: 'activate-their-sim-fp',
  FP_DEVICE_ACTIVATE_SIM: 'activate-device-sim-fp',
  FP_AWAITING_ACTIVATION: 'awaiting-activation-fp',
  FP_ACTIVATION_FAILED_MEMBER: 'something-went-wrong-fp-member',
  FP_MEMBER_AWAITING_ACTIVATION: 'awaiting-activation-member-fp',
  FP_PLEASE_LOGOUT: 'please-log-out-fp',
}

const planCollectionNames = {
  SOCIAL_PLANS: 'social_tariff_plans',
  VOICE_PLANS: 'voice_plans',
  MONEY_BACK_PLANS: 'money_back_plans',
  DATA_ONLY_PLANS: 'data_only_plans',
  UNSUPPORTED_PLANS: 'unsupported_plans',
  UNLIMITED_PLANS: 'unlimited_plans',
  CURRENT_OFFERS_PLANS: 'current_offers_plans',
  FAMILY_PLANS: 'family',
}

module.exports = {
  contentfulTypes,
  genericBannerNames,
  planCollectionNames,
}
